import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { calcRem } from '../../utils/mediaQueries';

import Link from './../Link/';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 30px;
`;

const Title = styled.h3`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  text-transform: uppercase;
  &:lang(en) {
    font-style: italic;
  }
  ${(props) => `color: ${props.titleColor}`}
`;

const Description = styled.h5`
  font-size: 17px;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  text-align: center;
  span{
    font-size:130%
  }
  ${(props) => `color: ${props.descriptionColor}`}
`;

const StyledLink = styled.div`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  font-size:14px;
  display: block;
  background-color: ${(props) => props.buttonBgColor};
  text-align: center;
  text-transform: uppercase;
  line-height:normal;
  color: ${(props) => props.buttonColor};
  transition: all 0.2s;
  padding: ${calcRem(7)} 40px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: ${calcRem(15)};
  transition: background 0.3s, color 0.3s;
  border-radius:20px;
  ${(props) =>
    props.buttonAlign === 'right' &&
    css`
      left:auto;
      right:20px;
      bottom: ${calcRem(10)};
      transform: translateX(0%);
    `}
`;

const BgImage = styled(Img)`
  width: ${(props) => props.bgWidth};
`;

const ProdImage = styled(Img)`
  position: absolute !important;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 50%;
  width: 187px;
  height: 187px;
`;

const Container = styled(Link)`
  position: relative;
  display: block;
  /* height: ${(props) => props.height}; */
  /* width: 316px; */
  /* ${(props) => `background-image: url(${props.bgImage})`}; */
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:hover ${StyledLink} {
    color: ${(props) => props.buttonBgColor};
    background-color: ${(props) => props.buttonColor};
    text-decoration: none;
  }
`;

const Card = ({
  title,
  titleColor,
  description,
  descriptionColor,
  path,
  height,
  bgImage,
  button,
  buttonColor,
  buttonBgColor,
  prodImage,
  alt,
  bgWidth,
  buttonAlign,
}) => {
  const { t } = useTranslation();

  let productImage = null;

  if (prodImage !== null) {
    switch (typeof prodImage) {
      case 'string':
        productImage = <img src={prodImage} alt={title} />;
        break;
      case 'object':
        productImage = <ProdImage fluid={prodImage} alt={title} />;
        break;
      default:
        break;
    }
  }
  // console.log('alt', alt);

  return (
    <Container
      height={height}
      to={path}
      buttonColor={buttonColor}
      buttonBgColor={buttonBgColor}
    >
      {typeof bgImage === 'string'? (
        <img src={bgImage} alt="background" />
      ) : (
        <BgImage fluid={bgImage} alt={alt} bgWidth={bgWidth} />
      )}

      <Wrapper>
        <Title titleColor={titleColor}>{t(title)}</Title>
        <Description
          descriptionColor={descriptionColor}
          dangerouslySetInnerHTML={{ __html: t(description) }}
        />
      </Wrapper>

      {productImage}

      {button && (
        <StyledLink
          to={path}
          buttonColor={buttonColor}
          buttonBgColor={buttonBgColor}
          buttonAlign={buttonAlign}
        >
          {t(button)}
        </StyledLink>
      )}
    </Container>
  );
};

export default Card;

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  titleColor: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  descriptionColor: PropTypes.string,
  path: PropTypes.string,
  height: PropTypes.string,
  bgImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  prodImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  titleColor: '#535355',
  description: null,
  descriptionColor: '#535355',
  path: '/',
  height: '375px',
  bgImage: null,
  button: 'learn_more',
  buttonColor: 'white',
  buttonBgColor: '#3471B0',
  prodImage: null,
  alt: '',
  bgWidth:'316px',
};
