import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Link from '../Link';

const Banner = ({ path, image }) => {
  return (
    <Link to={path}>
      <Img fluid={image} />
    </Link>
  );
};

export default Banner;
